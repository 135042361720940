var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('page-header',{attrs:{"title":_vm.$t('network.text_724'),"current-tab":_vm.cloudEnv},on:{"update:currentTab":function($event){_vm.cloudEnv=$event},"update:current-tab":function($event){_vm.cloudEnv=$event}}}),_c('page-body',{attrs:{"needMarginBottom":""}},[_c('a-form',{attrs:{"form":_vm.form.fc}},[_c('a-form-item',_vm._b({attrs:{"label":_vm.$t('network.text_205', [_vm.$t('dictionary.project')])}},'a-form-item',_vm.formItemLayout,false),[_c('domain-project',{attrs:{"fc":_vm.form.fc,"form-layout":_vm.formItemLayout,"decorators":{
            project: _vm.decorators.project,
            domain: _vm.decorators.domain,
          }},on:{"update:domain":_vm.domainChange}})],1),_c('area-selects',{ref:"areaSelects",staticClass:"mb-0",attrs:{"wrapperCol":_vm.formItemLayout.wrapperCol,"labelCol":_vm.formItemLayout.labelCol,"names":_vm.areaselectsName,"replace_list_key":_vm.replace_list_key,"cloudregionParams":_vm.regionParams,"providerParams":_vm.cloudProviderParams,"isRequired":true,"region":_vm.regionList,"filterBrandResource":"network_manage"},on:{"update:region":function($event){_vm.regionList=$event},"change":_vm.cloudregionChange}}),_c('a-form-item',_vm._b({attrs:{"label":_vm.$t('network.text_21')}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(_vm.decorators.name),expression:"decorators.name"}],attrs:{"placeholder":_vm.$t('validator.eipCreateName')}})],1),_c('a-form-item',_vm._b({attrs:{"label":_vm.$t('common.description')}},'a-form-item',_vm.formItemLayout,false),[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(_vm.decorators.description),expression:"decorators.description"}],attrs:{"auto-size":{ minRows: 1, maxRows: 3 },"placeholder":_vm.$t('common_367')}})],1),(_vm.showIpSubnet)?[_c('ip-subnet',{attrs:{"label":_vm.$t('network.text_211'),"isRequired":true,"labelCol":_vm.formItemLayout.labelCol,"wrapperCol":_vm.formItemLayout.wrapperCol,"decorator":_vm.decorators,"vpcParams":_vm.vpcParams,"networkParams":_vm.networkParams,"vpcResourceMapper":_vm.vpcResourceMapper,"showIpConfig":_vm.cloudEnv !== 'public',"helplink":{
            ipSubnetHelp: _vm.$t('network.eip.tip'),
            ipSubnetHref: '/network/create',
          }}})]:_vm._e(),_c('clearing-radios',_vm._b({attrs:{"auto_renew":false,"isMgEcloud":_vm.isMgEcloud}},'clearing-radios',_vm.formItemLayout,false)),(_vm.cloudEnv !== 'private' || _vm.isHCSO || _vm.isHCS)?[_c('a-form-item',_vm._b({attrs:{"label":_vm.$t('compute.text_1360')}},'a-form-item',_vm.formItemLayout,false),[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(_vm.decorators.charge_type),expression:"decorators.charge_type"}],on:{"change":_vm.chargeTypeChange}},_vm._l((_vm.chargeTypeOptions),function(item){return _c('a-radio-button',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1)],1),_c('a-form-item',_vm._b({attrs:{"label":_vm.$t('network.text_484')}},'a-form-item',_vm.formItemLayout,false),[_c('div',{staticClass:"d-flex align-items-center"},[_c('a-tooltip',{attrs:{"placement":"top","title":_vm.$t('network.eip.text_725', [_vm.maxBandwidth])}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(_vm.decorators.bandwidth),expression:"decorators.bandwidth"}],staticStyle:{"width":"120px"},attrs:{"min":1,"max":_vm.maxBandwidth,"step":_vm.cloudEnv === 'onpremise' ? 1 : 50,"formatter":_vm.format,"parse":_vm.format},on:{"blur":_vm.chargeBandwidth}})],1),_c('span',{staticClass:"ml-2"},[_vm._v("Mbps")])],1)])]:_vm._e(),_c('a-form-item',_vm._b({staticClass:"mb-0",attrs:{"label":_vm.$t('common.text00012')}},'a-form-item',_vm.formItemLayout,false),[_c('tag',{directives:[{name:"decorator",rawName:"v-decorator",value:(_vm.decorators.__meta__),expression:"decorators.__meta__"}],attrs:{"allowNoValue":false}})],1)],2)],1),_c('bottom-bar',{ref:"bottomBar",attrs:{"isHCSO":_vm.isHCSO || _vm.isHCS,"isMgEcloud":_vm.isMgEcloud,"current-cloudregion":_vm.selectedRegionItem,"size":_vm.bandwidth,"bgp-type":_vm.bgp_type,"cloudAccountId":_vm.cloudAccountId}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }